@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins&family=Raleway:ital,wght@0,400;0,500;0,700;1,400&display=swap');
@import "react-slideshow-image/dist/styles.css";

.App {
  font-family: 'Raleway', sans-serif;
  font-size: 1vw;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
}

.top-button {
  position: fixed;
  bottom: 1vw;
  right: 1vw;
  height: 2.8vw;
  width: 2.8vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.top-button-dark{
  background-color: rgba(248, 249, 250, 0.6);

}

.top-button svg {
  height: 1.3vw;
  width: 1.3vw;
  fill: var(--primary-light-color);
  transition: transform 0.3s ease-in-out;
}

.top-button-dark svg {
  fill: var(--primary-light-dark);

}

.top-button:hover {
  background-color: var(--primary-color);
  box-shadow: -2px -2px 8px rgba(0, 0, 0, 0.3); 
}

.top-button:hover svg {
  fill: var(--primary-dark);
  transform: rotate(360deg);
}

@media (max-width: 768px) {
  .top-button {
    bottom: 5vw;
    right: 5vw;
    height: 4.8vw;
    width: 4.8vw;
  }
  
  .top-button svg {
    height: 2.3vw;
    width: 2.3vw;
  }
}