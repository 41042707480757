.detailItem {
    display: flex;
    flex-direction: column;
}

.detailItem p{
    font-size: 1.5vw;
}

.detailItem a {
    color: var(--primary-dark);
}

.detailItem p:first-child{
    font-size: 1.2vw;
    padding-bottom: 1vw;
    font-weight: 700;
    color: var(--secondary-color);
}

.detailItem_dark p:first-child{
    color: var(--primary-grey);
}

.detailItem_dark p {
    color: var(--primary-light-grey);
}

.detailItem_dark a {
    color: var(--primary-light-grey);
}

@media (max-width: 768px) { 
    .detailItem p{
        font-size: 3vw;
    }

    .detailItem p:first-child{
        font-size: 2.5vw;
        padding-bottom: 1vw;
        font-weight: 700;
        color: var(--primary-light-dark);
    }

    .detailItem_dark p:first-child{
        font-size: 2.5vw;
        padding-bottom: 1vw;
        font-weight: 700;
        color: var(--primary-light-grey);
    }
}