.menuWrapper {
    width: 55%;
    font-size: 1.2vw;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2vw;
    width: 100%;
}

.menu li {
    list-style-type: none;
    position: relative;    
}

.menu li:hover {
    cursor: pointer;
}

.menu li::before {
    content: "";
    position: absolute;
    bottom: -7px; 
    left: 50%;
    width: 0;
    height: 2px;
    background-color: transparent;
    transition: width 0.3s ease, background-color 0.3s ease;
}

.menu li:hover::before {
    width: calc(100% + 20px); 
    background-color: var(--primary-color); 
    animation: underline-expand 0.5s forwards;
}

.menu a {
    text-transform: uppercase;
    text-decoration: none;
    color: var(--primary-light-dark);
}


.menu_dark a {
    color: var(--primary-light-grey);
}

@keyframes underline-expand {
    0% {
        left: 50%;
        width: 0;
    }
    100% {
        left: 0;
        width: 100%;
    }
}

.menu .active a {
    color: var(--primary-color);
}

.navBtnWrapper {
    display: none;
    width: 100%;
}

.navBtnDarkWrapper {
    display: none;
    width: 100%;
}

.navBtnWrapper svg {
    height: 6vw;
    width: 6vw;
    fill: var(--primary-light-dark);
}

.menuWrapper .navBtnDarkWrapper svg {
    height: 6vw;
    width: 6vw;
    fill: var(--primary-light-grey);
}

@media (max-width: 768px) {
    .menu  {
        display: none;
        position: absolute;
        box-sizing: border-box;
        left: 5%;
        top: 16vw;
        width: 90%;
        flex-direction: column;
        align-items: flex-start;
        padding: 3vw 4vw;
        background-color: var(--primary-light-color);
    }

    .menu li {
        width: 100%;
        padding: 3vw 0;
        font-size: 2.5vw;
        border-bottom: 1px solid var(--primary-medium-grey);
    }

    .menu a {
        color: var(--primary-light-dark);

    }

    .menu li:hover {
        cursor: auto;
    }

    .menu li::before {
        content: none;
    }

    .menu li:hover::before {
        width: 0;
        background-color: transparent;
        animation: none;
    }

    .navBtnWrapper {
        display: block;
    }

    .navBtnDarkWrapper {
        display: block;
    }

    .menuWrapper .responsiveNav {
        display: block;
    }

    .navBtnWrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .navBtnDarkWrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .navBtnWrapper svg {
        fill: var(--primary-light-dark);
    }

    .navBtnWrapperDark svg {
        fill: var(--primary-light-grey);
    }

    .navBtnWrapper svg:hover {
        cursor: pointer;
    }

    .menu_dark {
        background-color: var(--bg-dark-menu);
    }

    .menu_dark a {
        color: var(--primary-light-grey);
    }

}

@media screen and (min-width: 769px) and (max-width: 992px){
    .menuWrapper {
        font-size: 1.6vw;
    }
    
    .menu {
        gap: 1.8vw;
    }

}

@media screen and (min-width: 993px) and (max-width: 1200px){
    .menuWrapper {
        font-size: 1.5vw;
    }
    
    .menu {
        gap: 1.8vw;
    }
}