.footer {
    width: 100;
    min-height: 6vw;
    background-color: var(--primary-dark);
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_dark {
    background-color: var(--bg-dark);
}

.footer p {
    color: var(--primary-light-color);
    font-size: 1.2vw;
}

@media (max-width: 768px) {
    .footer {
        min-height: 12vw;
    }
    
    .footer p {
        font-size: 2.2vw;
    }
}