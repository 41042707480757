.qualityItem {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.5vw;
    padding: 0 1.5vw;
}

.qualityItem img {
    width: 2vw;
    transition: transform 0.3s ease-in-out;
}

.qualityItem p {
    font-size: 1.5vw;
}

.qualityItem p:hover {
    color: var(--warning-color);
    font-weight: 700;
}

@keyframes rotateLeft {
    0%, 100% {
        transform: rotate(-15deg);
    }
    50% {
        transform: rotate(15deg);
    }
}

.rotateIcon img {
    animation: rotateLeft 0.6s ease-in-out infinite;
}