.logoWrapper {
    width: 20%;
    display: flex;
    justify-content: center;
}

.logo {
    width: 100%;
    font-family: 'Pacifico', cursive;
    font-weight: 700;
    font-size: 3vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.5s ease;
}

.logo .logoText {
    color: var(--primary-light-dark);
    font-size: 4vw;
    padding-bottom: 0.8vw;
    margin-left: -0.1vw;
}

.logo:hover {
    cursor: pointer;
}


.logo span {
    color: var(--primary-color);
    transition: color 0.3s ease;
}

.logo_dark .logoText {
    color: var(--primary-light-grey);
}


@media (max-width: 768px) {
    .logo {
        font-size: 8vw;
        padding-bottom: 2vw;
    }

    .logo .logoText {
        font-size: 9vw;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .logoWrapper {
        width: 16%;
    }

    .logo {
        font-size: 5vw;
        padding-bottom: 1.25vw;
    }

    .logo .logoText {
        font-size: 6vw;
    }

}

@media screen and (min-width: 993px) and (max-width: 1200px){
    .logoWrapper {
        width: 16%;
    }

    .logo {
        font-size: 4.2vw;
        padding-bottom: 1.1vw;
    }

    .logo .logoText {
        font-size: 5.2vw;
    }
}