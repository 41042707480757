.about {
    min-height: 100vh;
    width: 100%;
    background-color: var(--primary-light-color);
    padding: 6vw 8vw;
}

.about_dark {
    background-color: var(--bg-dark);
    color: var(--primary-light-grey);
}

.aboutWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.aboutHeading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5vw;
}

.aboutHeading  div {
    background-color: var(--primary-color);
    font-size: 1.2vw;
    padding: 0.4vw 0.8vw;
}

.safariLess750 .aboutHeading  div {
    margin-bottom: 2vw;
}

.about_dark .sectionLable {
    color: var(--primary-light-dark);
}

.aboutHeading > h2 {
    font-size: 3vw;
    padding-bottom: 4.5vw;
}

.aboutInfoWrapper {
    display: flex;
    align-items: center;
}

.aboutDescription {
    width: 55%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.aboutDescription h3 {
    font-size: 2vw;
    padding-bottom: 1.5vw;
}

.aboutDescription h3 span{
    border-bottom: 0.3vw solid var(--primary-color); 
}

.aboutDescription p {
    font-size: 1.5vw;
    line-height: 1.5;
    padding-right: 1.5vw;
}

.aboutQualitiesWrapper {
    height: 100%;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5vw;
}

.aboutDetails {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 6vw;
}

@media (max-width: 768px) {
    .about {
        min-height: auto;
        padding: 10vw 8vw;
    }

    .aboutHeading {
        gap: 2vw;
    }
    
    .aboutHeading > div {
        font-size: 2.8vw;
        padding: 0.6vw 1.2vw;
    }
    
    .aboutHeading > h2 {
        font-size: 5vw;
        padding-bottom: 6vw;
    }

    .aboutDescription {
        width: 100%;
    }

    .aboutDescription h3 {
        font-size: 4vw;
        padding-bottom: 3vw;
    }
    
    .aboutDescription h3 span{
        border-bottom: 0.3vw solid var(--primary-color); 
    }
    
    .aboutDescription p {
        font-size: 3vw;
        line-height: 2;
        padding-right: 0;
    }

    .aboutQualitiesWrapper {
        display: none;
    }

    .aboutDetails {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 6vw; 
    }

}

@media screen and (min-width: 769px) and (max-width: 992px){
    .about {
        min-height: auto;
        padding: 6vw 8vw;
        display: flex;
        align-items: center;
    }

    .aboutDescription p {
        font-size: 1.9vw;
        line-height: 1.7;
    }

}

@media screen and (min-width: 993px) and (max-width: 1200px){
    .about {
        min-height: auto;
        padding: 6vw 8vw;
        display: flex;
        align-items: center;
    }

    .aboutDescription p {
        font-size: 1.8vw;
        line-height: 1.7;
    }
}
