.skillsItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;
    gap: 1.5vw;
}

.safariLess750 .skillsItem {
    margin-bottom: 1.5vw;
}

.skillsItem svg {
    width: 5vw;
    height: 5vw;
    fill: var(--secondary-color);
    transition: transform 0.3s ease-in-out;
}

.safariLess750 .skillsItem svg {
    margin-bottom: 0.5vw;
}

.skillsItem_dark svg {
    fill: var(--icon-color-dark);
}

.skillsItem svg:hover {
    transform: scale(1.1);
}

.skillsItem p {
    font-size: 1.5vw;
    font-weight: 700;
}

.safariLess750 .skillsItem p {
    margin-bottom: 0.5vw;
}

.barWrapper {
    width: 80%;
    background-color: var(--primary-grey);
    border-radius: 10px;
}

.skillsItem_dark .barWrapper {
    background-color: var(--primary-light-grey);
}

.skillsItem_dark .bar {
    color: var(--primary-light-dark);
}

.bar {
    height: 1.5vw;
    background-color: var(--primary-color);
    border-radius: 10px;
    text-align: right;
    padding-right: 0.5vw;
    font-size: 1.2vw;
    font-weight: 700;
}

@media (max-width: 768px) {
    .skillsItem {
        gap: 2vw;
    }
    
    .skillsItem img {
        max-width: 10vw;
        max-height: 10vw;
    }
    
    .skillsItem p {
        font-size: 2.2vw;
    }
    
    .barWrapper {
        width: 80%;
    }
    
    .bar {
        height: 2.5vw;
        font-size: 2vw;
    }
}