.home {
    min-height: calc(100vh - 6vw);
    width: 100%;
    background-color: var(--primary-color);
    margin-top: 6vw;
}

.homeWrapper {
    padding: 0 8vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.greetWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 5vw;
  width: 65%;
}

h1 {
    font-size: 3.8vw;
    margin: 0;
    font-weight: 100;
}

.runningMessage {
  font-size: 8vw;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
}

.greetMessage {
  margin: 0;
  font-size: 3.8vw;
}

.greetLocation {
  margin: 0;
  font-size: 1.4vw;
  padding-top: 2vw;
  font-weight: 500;
}

.handleWrapper {
  display: flex;
  align-items: center;
  gap: 5vw;
  margin-top: 3vw;
}

.safariLess750 .handleWrapper button {
  margin-right: 10vw;
}

.handleWrapper button {
  border: none;
  background-color: var(--primary-dark);
  color: var(--primary-light-color);
  text-transform: uppercase;
  letter-spacing: 0.2vw;
  font-size: 1.2vw;
  padding: 1.3vw 2.6vw;
  transition: background-color 0.2s ease-in;
}

.handleWrapper button:hover {
  background-color: var(--primary-light-dark);
  cursor: pointer;
}

.handleWrapper a {
  font-size: 1.4vw;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1vw;
  color: var(--primary-dark);
}

.handleWrapper a:hover {
  text-decoration: none;
}

.photoWrapper {
  margin-top: 1vw;
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.photoWrapper img {
  width: 32vw;
  /* height: 32vw; */
  margin-top: 4.5vw;
  filter: grayscale(100%);
}

.safariLess750 .photoWrapper img {
  width: 55vw;
  height: 55vw;
  margin-top: 4.5vw;
  filter: grayscale(100%);
}

@media screen and (max-width: 768px) {
  .home {
    min-height: calc(100vh - 16vw);
    padding-top: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .homeWrapper {
    flex-direction: column-reverse;
  }

  .greetWrapper {
    align-items: center;
    width: 100%;
  }
  
  h1 {
      font-size: 6vw;
      padding-bottom: 2vw;
  }
  
  .runningMessage {
    font-size: 8vw;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
  }
  
  .greetMessage {
    padding-top: 1vw;
    font-size: 6vw;
  }
  
  .greetLocation {
    font-size: 3.5vw;
  }

  .handleWrapper {
    display: flex;
    align-items: center;
    gap: 15vw;
    padding-top: 4vw;
    padding-bottom: 10vw;
  }

  .handleWrapper button {
    font-size: 2.8vw;
    padding: 2vw 4vw;
  }
  
  .handleWrapper a {
    font-size: 2.8vw;
  }

  .photoWrapper {
    padding-top: 4vw;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .photoWrapper picture {
    width: 100%;
    margin-top: 10vw;
    display: flex;
    justify-content: center;
  }

  .photoWrapper picture img {
    width: 60vw;
    margin-top: 0;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px){
  .home {
    min-height: 88vh;
    display: flex;
    align-items: center;
    margin-top: 12vh;
  }

  .homeWrapper {
    width: 100%;
  }

  .photoWrapper {
    padding-top: 1.4vw;
  }

}

@media screen and (min-width: 993px) and (max-width: 1200px){
  .home {
    min-height: 90vh;
    display: flex;
    align-items: center;
    margin-top: 10vh;
  }

  .homeWrapper {
    width: 100%;
  }

  .photoWrapper {
    padding-top: 2vw;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1500px){
  .homeWrapper {
    padding-top: 2.8vw;
  }
}