.socialWrapper {
    width: 15%;    
}

.socialWrapper ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5vw;
    margin: 0;
    padding: 0;
}

.socialWrapper li {
    list-style-type: none;
}

.socialWrapper svg {
    fill: var(--primary-light-dark);
    height: 1.5vw;
    width: 1.5vw;
    transition: all 0.3s ease-in-out;
}

.socialWrapper svg:hover {
    cursor: pointer;
    transform: scale(1.1);
    fill: var(--primary-color);
}

.socialWrapper_dark svg {
    fill: var(--primary-light-grey);
}

@media (max-width: 768px) {
    .socialWrapper {
        display: none;   
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .socialWrapper  {
        display: none;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px){
    .socialWrapper svg {
        height: 2.4vw;
        width: 2.4vw;
    }
}