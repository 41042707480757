.navigation {
    height: 6vw;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 8vw;
    position: fixed;
    background-color: var(--primary-light-color);
    box-sizing: border-box;
    top: 0;
    left: 0;
    z-index: 10;
    border-bottom: 1px solid var(--primary-grey);
}

.navigation_dark {
    background-color: var(--bg-dark);
    border-bottom: 1px solid var(--primary-dark);

}

@media (max-width: 768px) {
    .navigation {
        height: 16vw;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .navigation {
        height: 12vw;
    }

}

@media screen and (min-width: 993px) and (max-width: 1200px){
    .navigation {
        height: 10vw;
    }
}
