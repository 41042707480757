.qualities {
    width: 100%;
}

.qualitiesWrapper {
    width: 100%;
    height: 100%;
    padding: 6vw 8vw 6vw 8vw;
    display: grid;
    grid-template-rows: repeat(1, auto);
    grid-template-columns: repeat(4, 1fr);
    gap: 4vw;
}

@media (max-width: 768px) {
    .qualities{
        display: none;
    }
}