.resumeItem {
    margin: 0 1vw;
    padding-bottom: 1vw;
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
    border-bottom: 1px solid var(--primary-grey);
}

.safariLess750 .resumeItem {
    margin-bottom: 1.1vw;
}

@media (max-width: 768px) {
    .resumeItem {
        margin: 0 1.5vw;
        padding: 2.5vw 0;
        gap: 1.5vw;
    }
}