.portfolioItem {
    position: relative;
    overflow: hidden;
    max-height: 0;
    transition: all 0.20s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.portfolioItem:hover {
    cursor: pointer;
}

.visible {
    max-height: 100%;
    transition: all 0.20s ease-in;
}

.safariOver1200.visible {
    height: 33.5vw;
    transition: all 0.20s ease-in;
}

.portfolioItem img{
    width: 100%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1200px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .portfolioItem {
        height: 35vw;
    }
    
    .portfolioItem img {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1200px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .portfolioItem {
        height: 70vw;
    }
    
    .portfolioItem img {
        width: 100%;
        height: 100%;
    }

}


.overlay {
    position: absolute;
    top: 1vw;
    left: 0;
    width: 100%;
    height: 67%;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    color: var(--primary-light-color);
    padding: 3vw;
}

.overlay h2 {
    font-size: 2vw;
    padding-bottom: 1vw;
}

.overlay img {
    height: 5vw;
    fill: var(--primary-color);
    padding-bottom: 1vw;
}

.overlay .techWrapper {
    width: 100%;
    display: flex;
    gap: 1vw;
}

.overlay .techWrapper svg {
    fill: var(--primary-light-grey);
    height: 1.4vw;
    width: 1.4vw;
}

.hoverReady:hover .overlay {
    opacity: 1;
}

.overlay .description {
    text-align: justify;
    border-top: 2px dotted var(--primary-color);
    padding-top: 1vw;
}

.description span {
    background-color: transparent;
    width: 100%;
    display: inline-block;
}

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 3vw 8vw;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1005;
    opacity: 0;
    visibility: hidden;
    transition: all .5s;
}

.active {
    opacity: 1;
    visibility: visible;
    transition: all .5s;
}

.popupContent {
    background-color: transparent;
    width: 100%;
    max-height: 100%;
    text-align: center;
    box-sizing: border-box;
    position: relative;
}

.closeBtnWrapper {
    position: absolute;
    top: -0.4vw;
    right: -5.5vw;
    width: 3vw;
    height: 3vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.closeBtnWrapper:hover {
    cursor: pointer;
}

.closeBtnWrapper .closeBtn {
    fill: var(--primary-light-color);
    height: 2.5vw;
    width: 2.5vw;
}

@media (max-width: 768px) {
    .overlay {
        padding: 7vw;
    }

    .overlay h2 {
        font-size: 3vw;
        padding-bottom: 2vw;
    }
    
    .overlay img {
        height: 8vw;
        fill: var(--primary-color);
        padding-bottom: 2vw;
    }

    .overlay .description {
        font-size: 2.6vw;
        padding-top: 2vw;
    }

    .overlay .techWrapper {
        gap: 2vw;
    }

    .overlay .techWrapper svg {
        height: 2.8vw;
        width: 2.8vw;
    }

    .popupOverlay {
        padding: 3vw 3vw;
        background-color: rgba(0, 0, 0, 0.8);
    }
    
    .popupContent {
        padding-top: 30%;
    }
    
    .closeBtnWrapper {
        position: absolute;
        top: 5vw;
        right: 5vw;
        width: 5vw;
        height: 5vw;
    }
    
    .closeBtnWrapper .closeBtn {
        height: 5vw;
        width: 5vw;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .popupContent {
        padding-top: 11.5%;
    }

}

@media screen and (min-width: 993px) and (max-width: 1200px){
    .popupContent {
        padding-top: 11.5%;
    }

}
