.mode {
    width: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modeWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2vw;
    padding: 0.35vw;
    border-radius: 50%;
}

.modeWrapper:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
}

.mode svg {
    width: 1.7vw;
    height: 1.7vw;
    transition: all 0.15s ease-in;
}

.modeWrapper_dark svg {
    fill: var(--primary-light-grey);
}

.modeWrapper_dark:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
    .mode {
        padding-left: 5vw;
    }

    .mode svg {
        width: 5vw;
        height: 5vw;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .mode {
        padding-left: 2vw;
    }

    .mode svg {
        width: 2.8vw;
        height: 2.8vw;
    }

}

@media screen and (min-width: 993px) and (max-width: 1200px){
    .mode {
        padding-right: 2vw;
    }

    .mode svg {
        width: 2.4vw;
        height: 2.4vw;
    }
}