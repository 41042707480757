.dataItem {
    display: flex;
    gap: 1vw;
}

.iconWrapper {
    width: 1.5vw;
    display: flex;
    align-items: center;
}

.safariLess750 .iconWrapper {
    margin-right: 1.5vw;
}

svg {
    width: 1.5vw;
    height: 1.5vw;
    fill: var(--primary-light-dark);
}

.dataItem_dark svg {
    fill: var(--primary-light-grey);
}


.dataItem p {
    font-size: 1.2vw;
}

.dataItem span {
    font-weight: 700;
}

.dataItem .marked {
    font-size: 1.5vw;
    font-weight: 700;
}

@media (max-width: 768px) {
    .dataItem {
        gap: 3vw;
        display: flex;
        align-items: center;
    }
    .iconWrapper {
        min-width: 3vw;
    }
    
    .iconWrapper svg{
        height: 3vw;
        width: 3vw;
    }
    
    .dataItem p {
        font-size: 3vw;
    }
    
    .dataItem .marked {
        font-size: 2.5vw;
    }

    .dataItem .marked {
        font-size: 3vw;
    }
}